import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  InputAdornment,
  Typography,
} from '@mui/material';
import { FormNumberInput } from '../../../../../component/form/FormNumberInput';
import { FormDropdown } from '../../../../../component/form/FormDropdown';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { Control } from 'react-hook-form';
import { FinancesDrawerFormValues } from '../RightDrawerSchema';
import { OptionItem } from '../../../../../generated/ApiClient';
import React from 'react';
import { FormTextField } from '../../../../../component/form/FormTextField';
import { FormPriceInput } from '../../../../../component/form/FormPriceInput';
import { FormRadioButton } from '../../../../../component/form/FormRadioButton';
import { FormCheckbox } from '../../../../../component/form/FormCheckbox';

interface FinancesCalculationParameterProps {
  control: Control<FinancesDrawerFormValues>;
  fundSources: OptionItem[];
  businessRelationships: OptionItem[];
  onRelevantChange: (name: string, value: unknown) => void;
  hasDeposit: boolean;
  hasClosingBalance: boolean;
  isFinancingLeasing: boolean;
  hasResidualValue: boolean;
  isDepositReadonly: boolean;
  isDownPaymentReadonly: boolean;
  isAccountOwnerRequired: boolean;
  showSourceOfFunds: boolean;
  isResidualValueReadonly: boolean;
  isResidualValueConversionOptionsReadonly: boolean;
  isIncludeVat: boolean;
  isCredit: boolean;
  isLeasing: boolean;
  vatFree: boolean;
  loading: boolean;
  readonly?: boolean;
  isDirectInkassoVisible: boolean;
}

export const FinancesCalculationParameter = ({
  control,
  fundSources,
  businessRelationships,
  onRelevantChange,
  hasDeposit,
  hasResidualValue,
  isFinancingLeasing,
  isDepositReadonly,
  isDownPaymentReadonly,
  isAccountOwnerRequired,
  showSourceOfFunds,
  hasClosingBalance,
  isResidualValueReadonly,
  isResidualValueConversionOptionsReadonly,
  isIncludeVat,
  isCredit,
  vatFree,
  isLeasing,
  loading,
  readonly,
  isDirectInkassoVisible,
}: FinancesCalculationParameterProps) => {
  const { t } = useTranslation();

  const handleRelevantBlurEvent = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onRelevantChange(event.target.name, event.target.value);
  };

  const handleRelevantChangeEvent = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    onRelevantChange(event.target.name, event.target.value);
  };

  return (
    <Grid container spacing={3} sx={{ 'p': 1, '& .MuiFormControl-root': { marginLeft: 0 } }}>
      <Grid item xs={6}>
        <h3>{t('deals.new.financesConfiguration.calculationDrawer.parameter.parameters')}</h3>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            width: '100%',
            gap: 2,
          }}
        >
          <FormNumberInput
            control={control}
            name="downPaymentPercentage"
            label={t(
              'deals.new.financesConfiguration.calculationDrawer.parameter.prePaymentPercent'
            )}
            sx={{ width: '40%', mt: 1, mb: 1 }}
            endAdornment={<span>%</span>}
            fixedDecimalScale
            shrink
            onBlur={handleRelevantBlurEvent}
            disabled={isDownPaymentReadonly || loading || readonly}
          />

          <FormPriceInput
            pricesIncludingVAT={isIncludeVat}
            vatFree={vatFree}
            control={control}
            name="downPayment"
            label={t('deals.new.financesConfiguration.calculationDrawer.parameter.prePaymentEuro')}
            sx={{ width: '60%', mt: 1, mb: 1 }}
            shrink
            onBlur={handleRelevantBlurEvent}
            disabled={isDownPaymentReadonly || loading || readonly}
          />
        </Box>

        <Box
          sx={{
            display: hasDeposit ? 'flex' : 'none',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            width: '100%',
            gap: 2,
          }}
        >
          <FormNumberInput
            control={control}
            name="depositPercentage"
            label={t('deals.new.financesConfiguration.calculationDrawer.parameter.depositPercent')}
            endAdornment={<span>%</span>}
            fixedDecimalScale
            shrink
            onBlur={handleRelevantBlurEvent}
            sx={{ width: '40%', mt: 1, mb: 1 }}
            disabled={isDepositReadonly || loading || readonly}
          />

          <FormNumberInput
            control={control}
            name="deposit"
            fixedDecimalScale
            label={t('deals.new.financesConfiguration.calculationDrawer.parameter.depositEuro')}
            startAdornment={<InputAdornment position="start">€</InputAdornment>}
            shrink
            onBlur={handleRelevantBlurEvent}
            sx={{ mb: 1, mt: 1 }}
            fullWidth
            disabled={isDepositReadonly || loading || readonly}
          />
        </Box>
        {showSourceOfFunds && (
          <FormDropdown
            control={control}
            name="sourceOfFoundsId"
            label={t('deals.new.financesConfiguration.calculationDrawer.parameter.sourceOfFunds')}
            onChange={handleRelevantChangeEvent}
            options={fundSources}
            disabled={loading || readonly}
            required
            shrink
            sx={{ m: 1 }}
          />
        )}

        <FormDropdown
          control={control}
          name="businessRelationshipId"
          label={t('deals.new.financesConfiguration.calculationDrawer.parameter.business')}
          options={businessRelationships}
          onChange={handleRelevantChangeEvent}
          shrink
          disabled={loading || readonly}
          sx={{ m: 1 }}
        />

        {isDirectInkassoVisible && (
          <FormCheckbox
            control={control}
            name="isDirectInkasso"
            label={t('deals.new.financesConfiguration.calculationDrawer.parameter.isDirectInkasso')}
            disabled={loading || readonly}
          />
        )}

        {isAccountOwnerRequired && (
          <FormTextField
            control={control}
            name="accountOwner"
            label={t('deals.new.financesConfiguration.calculationDrawer.parameter.accountOwner')}
            sx={{ width: '100%', margin: 1 }}
            required
            disabled={loading || readonly}
          />
        )}
      </Grid>
      <Grid item xs={6} sx={{ mt: '37.5px' }}>
        {hasResidualValue && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              alignItems: 'start',
              width: '100%',
              py: 2,
              mt: 2,
              gap: 2,
            }}
          >
            <FormNumberInput
              control={control}
              name="residualValuePercentage"
              onBlur={handleRelevantBlurEvent}
              endAdornment={<span>%</span>}
              fixedDecimalScale
              label={t(
                'deals.new.financesConfiguration.calculationDrawer.parameter.residualValuePercentage'
              )}
              sx={{ width: '30%' }}
              shrink
              required
              disabled={isResidualValueReadonly || loading || readonly}
            />

            <Box sx={{ width: '60%', mr: 1 }}>
              <FormPriceInput
                pricesIncludingVAT={isIncludeVat}
                control={control}
                name="residualValueVehicleAbsolute"
                onBlur={handleRelevantBlurEvent}
                label={t(
                  'deals.new.financesConfiguration.calculationDrawer.parameter.residualValueVehicle'
                )}
                shrink
                required
                vatFree={vatFree}
                disabled={isResidualValueReadonly || loading || readonly}
              />
            </Box>
            <Box sx={{ width: '100%', mr: 1 }}>
              <FormPriceInput
                control={control}
                name="residualValueConversionOptions"
                onBlur={handleRelevantBlurEvent}
                label={t(
                  'deals.new.financesConfiguration.calculationDrawer.parameter.residualValueConversionOptions'
                )}
                shrink
                pricesIncludingVAT={isIncludeVat}
                vatFree={vatFree}
                disabled={isResidualValueConversionOptionsReadonly || loading || readonly}
              />
            </Box>

            <Box sx={{ width: '100%', mr: 1 }}>
              <FormPriceInput
                control={control}
                name="residualValue"
                label={t(
                  'deals.new.financesConfiguration.calculationDrawer.parameter.residualValue'
                )}
                shrink
                pricesIncludingVAT={isIncludeVat}
                vatFree={vatFree}
                disabled={true}
              />
            </Box>
          </Box>
        )}

        {isFinancingLeasing && (
          <FormPriceInput
            pricesIncludingVAT={isIncludeVat}
            control={control}
            name="finalRateFinancingLeasing"
            label={t('deals.new.financesConfiguration.calculationDrawer.parameter.finalRateEuro')}
            sx={{ width: '60%', mt: 1, mb: 1 }}
            shrink
            vatFree={vatFree}
            required
            disabled
          />
        )}
      </Grid>
      <Accordion
        sx={{
          'boxShadow': 'none',
          '&:before': {
            display: 'none',
          },
          'mt': 3,
          'ml': 2,
          'width': '100%',
        }}
        disableGutters={true}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ p: 0, mr: 0 }}
        >
          <Typography sx={{ ml: 1 }} noWrap>
            {t('deals.new.financesConfiguration.calculationDrawer.parameter.detailed')}
          </Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              gap: 2,
            }}
          >
            {isLeasing && (
              <FormPriceInput
                control={control}
                name="outstandingBalance"
                label={t(
                  'deals.new.financesConfiguration.calculationDrawer.parameter.outstandingBalance'
                )}
                sx={{ width: '100%' }}
                shrink
                pricesIncludingVAT={isIncludeVat}
                vatFree={vatFree}
                readOnly={true}
              />
            )}
            {isLeasing && (
              <FormPriceInput
                control={control}
                name="equity"
                label={t('deals.new.financesConfiguration.calculationDrawer.parameter.equity')}
                sx={{ width: '100%' }}
                shrink
                pricesIncludingVAT={isIncludeVat}
                vatFree={vatFree}
                readOnly={true}
              />
            )}
            {hasResidualValue && (
              <>
                <FormPriceInput
                  control={control}
                  name="tableResidualValue"
                  label={t(
                    'deals.new.financesConfiguration.calculationDrawer.parameter.tableResidualValue'
                  )}
                  sx={{ width: '100%' }}
                  shrink
                  pricesIncludingVAT={isIncludeVat}
                  vatFree={vatFree}
                  readOnly={true}
                />
                <FormPriceInput
                  control={control}
                  name="finalInstalmentIncludingConversion"
                  label={t(
                    'deals.new.financesConfiguration.calculationDrawer.parameter.finalInstalmentIncludingConversion'
                  )}
                  sx={{ width: '60%', mt: 1, mb: 1 }}
                  shrink
                  pricesIncludingVAT={isIncludeVat}
                  vatFree={vatFree}
                  readOnly={true}
                />
                <FormPriceInput
                  control={control}
                  name="residualValueSupport"
                  label={t(
                    'deals.new.financesConfiguration.calculationDrawer.parameter.residualValueSupport'
                  )}
                  sx={{ width: '100%' }}
                  shrink
                  pricesIncludingVAT={isIncludeVat}
                  vatFree={vatFree}
                  readOnly={true}
                />
              </>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};
