import { CustomerContextValues } from '../customer/CustomerForm';

import { Applicant } from '../applicant/ApplicantForm';
import { ModelFormValues } from '../model/ModelForm';
import {
  ConversionOptionValues,
  FinancesCalculationContextValues,
  FinancesDrawerFormValues,
  ObjectConfigValues,
  SalesAidValues,
  ServiceValues,
  VinConfigValues,
} from '../../common/financeOverlay/RightDrawerSchema';
import {
  AgentOption,
  ApiconSpecialCasePriceFormState,
  ApiconSpecialCaseRequestBundle,
  CalculationServiceRequest,
  CommercialCustomerView,
  ConversionOption,
  CredibilityCheck,
  CustomerType,
  Deal,
  DealCalculation,
  DealCustomer,
  ObjectConfigurationRequest,
  PrivateCustomerView,
  SalesAidView,
  SaveCalculationRequest,
  ServiceOption,
  SubsidyView,
  VinAssignment,
} from '../../../../generated/ApiClient';

export const mapCustomerContextToDto = (
  customer: CustomerContextValues | Applicant
): DealCustomer => {
  const dealCustomer = new DealCustomer();
  dealCustomer.customerId = customer.customerId ? Number(customer.customerId) : undefined;

  if (customer.customer) {
    dealCustomer.applyChangesToMainCustomer = customer.applyChangesToMainCustomer;
    if (customer.type === CustomerType.Private) {
      dealCustomer.privateCustomer = new PrivateCustomerView();
      dealCustomer.privateCustomer.init(customer.customer);
    } else if (customer.type === CustomerType.Commercial) {
      dealCustomer.commercialCustomer = new CommercialCustomerView();
      dealCustomer.commercialCustomer.init(customer.customer);
    }
  }

  return dealCustomer;
};

export const mapModelContextToDto = (model: ModelFormValues): Deal => {
  return new Deal({
    modelId: model.modelId,
    dealTypeId: model.businessType,
    vehicleTypeConditionId: model.vehicleCondition,
    discountCategoryId: model.discountCategory,
    salesPersonId: model.sellerId,
    initialMileage: model.mileage ?? undefined,
    commissionNumber: model.commissionNumber,
    listPrice: model.listPrice,
    extrasPrice: model.specialEquipment,
    purchasePrice: model.totalPrice,
    discountPercent: model.discountInPercentages,
    discountInEuro: model.discountInEuro,
    numberOfVehicles: model.numberOfVehicles,
    vat: 20,
    isIncludeVat: model.pricesIncludingVAT,
    registrationDate: model.registrationDate,
    distributionChannel: model.distributionChannel,
    modelAddition: model.modelAddition,
    modelPrototypeId: model.modelPrototypeId,
    chassisNumber: model.chassisNumber,
  });
};

export const mapCalculationToDto = (
  calculation: FinancesCalculationContextValues
): DealCalculation => {
  const objectConfig = new ObjectConfigurationRequest();
  objectConfig.init(calculation.objectConfig);
  return new DealCalculation({
    id: calculation.id ?? 0,
    isDirectInkasso: calculation.isDirectInkasso ?? false,
    financingTypeId: calculation.financingTypeId,
    duration: calculation.duration,
    mileageId: calculation.mileageId,
    businessRelationshipId: calculation.businessRelationshipId,
    interestRateCalculationMethodId: calculation.interestRateCalculationMethodId,
    rateCategoryId: calculation.rateCategoryId,
    downpayment: calculation.downPayment ?? undefined,
    downPaymentPercent: calculation.downPaymentPercentage ?? undefined,
    deposit: calculation.deposit ?? undefined,
    depositPercent: calculation.depositPercentage ?? undefined,
    residualValueContractId: calculation.residualValueId,
    sourceOfFoundsId: calculation.sourceOfFoundsId,
    accountOwner: calculation.accountOwner ?? undefined,
    residualValueConversionOptions: calculation.residualValueConversionOptions ?? undefined,
    residualValueVehicleAbsolute: calculation.residualValueVehicleAbsolute ?? undefined,
    residualValuePercentage: calculation.residualValuePercentage ?? undefined,
    objectConfig: objectConfig,
    calculationServiceRequest: new CalculationServiceRequest(),
    purchaseContractDate: calculation.purchaseContractDate ?? undefined,
    salesAid: calculation.salesAid ? mapSalesAidValuesToView(calculation.salesAid) : undefined,
    specialOfferId: calculation.specialOfferId ?? undefined,
    referencesCalculationId: calculation.referencesCalculationId ?? undefined,
    referencesDealId: calculation.referencesDealId ?? undefined,
    agentOptions: calculation.agentOptions
      ? calculation.agentOptions.map(
          (ao) =>
            new AgentOption({
              id: Number.isInteger(ao.id) ? ao.id : 0,
              title: ao.title,
              dealerId: ao.dealerId,
              price: ao.price,
            })
        )
      : [],
    conversionOptions: calculation.conversionOptions
      ? calculation.conversionOptions.map(mapConvOptionValuesToView)
      : [],
    selectedFuelServiceProductId: calculation.selectedFuelServiceProductId || undefined,
  });
};

export const mapCalculationFormToSaveDto = (
  dealId: number,
  values: FinancesDrawerFormValues,
  salesAids: SalesAidView[],
  isServiceVariantSaveRequest: boolean,
  productOptions: ServiceOption[],
  calculationId?: number
): SaveCalculationRequest => {
  const saveCalcRequest = new SaveCalculationRequest();
  saveCalcRequest.dealId = dealId;
  saveCalcRequest.calculationId = calculationId;
  const calculation = new DealCalculation();
  calculation.financingTypeId = values.financingTypeId;
  calculation.duration = values.duration;
  calculation.mileageId = values.mileageId;
  calculation.businessRelationshipId = values.businessRelationshipId;
  calculation.interestRateCalculationMethodId = values.interestRateCalculationMethodId;
  calculation.rateCategoryId = values.rateCategoryId;
  calculation.downpayment = values.downPayment ?? undefined;
  calculation.downPaymentPercent = values.downPaymentPercentage ?? undefined;
  calculation.deposit = values.deposit ?? undefined;
  calculation.depositPercent = values.depositPercentage ?? undefined;
  calculation.residualValueContractId = values.residualValueId;
  calculation.sourceOfFoundsId = values.sourceOfFoundsId;
  calculation.accountOwner = values.accountOwner ?? undefined;
  calculation.residualValueVehicleAbsolute = values.residualValueVehicleAbsolute ?? undefined;
  calculation.residualValueConversionOptions = values.residualValueConversionOptions ?? undefined;
  calculation.residualValuePercentage = values.residualValuePercentage ?? undefined;
  calculation.salesAid = values.salesAid ? mapSalesAidValuesToView(values.salesAid) : undefined;
  calculation.specialOfferId = values.specialOfferId ?? undefined;
  calculation.purchaseContractDate = values.purchaseContractDate ?? undefined;
  calculation.objectConfig = new ObjectConfigurationRequest({
    ...values.objectConfig,
    initialMileage: values.objectConfig.initialMileage ?? undefined,
    commissionNumber: values.objectConfig.commissionNumber ?? undefined,
  });
  calculation.calculationServiceRequest = new CalculationServiceRequest({
    calculationId: calculationId ?? -1,
    serviceVariantId: values.service.serviceVariantId ?? undefined,
    variantNumber: undefined,
    isServiceVariantSaveRequest: isServiceVariantSaveRequest,
    cacheGuid: values.service.cacheGuid ?? undefined,
    mileageId: values.service.mileageId ?? -1,
    serviceProductId: values.service.apiconServiceProduct?.selectedServiceProductId ?? '',
    productIndustryId: values.service.apiconServiceProduct?.selectedProductIndustryId ?? '',
    productUsageId: values.service.apiconServiceProduct?.selectedProductUsageId ?? '',
    campaignId: values.service.apiconServiceProduct?.selectedCampaignId ?? '',
    productOptionIds: values.service.apiconServiceProduct?.selectedProductOptions ?? [],
    technicalOptionIds: values.service.apiconServiceProduct?.selectedTechnicalOptions ?? [],
    priceNet: values.service.apiconPriceForm?.priceNet ?? undefined,
    totalPrice: values.service.apiconPriceForm?.totalPrice ?? undefined,
    priceFormState:
      values.service.apiconPriceForm?.priceFormState ?? ApiconSpecialCasePriceFormState.Initial,
    configurationID: values.service.apiconPriceForm?.configurationId ?? '',
    requestSourceApiconServiceBundleResetCalculation:
      values.service.requestSourceApiconServiceBundleResetCalculation ?? false,
  });
  calculation.selectedFuelServiceProductId = values.selectedFuelServiceProductId || undefined;
  calculation.referencesCalculationId = values.referencesCalculationId ?? undefined;
  calculation.referencesDealId = values.referencesDealId ?? undefined;
  saveCalcRequest.overtakenCustomerId = values.overtakenCustomerId ?? undefined;
  saveCalcRequest.calculation = calculation;
  saveCalcRequest.removeReconsiderationReference =
    values.removeReconsiderationReference ?? undefined;
  saveCalcRequest.salesAids = salesAids;
  saveCalcRequest.calculation.agentOptions = values.agentOptions
    ? values.agentOptions.map(
        (ao) =>
          new AgentOption({
            id: Number.isInteger(ao.id) ? ao.id : 0,
            title: ao.title,
            dealerId: ao.dealerId,
            price: ao.price,
          })
      )
    : [];
  saveCalcRequest.calculation.conversionOptions = values.conversionOptions
    ? values.conversionOptions.map(mapConvOptionValuesToView)
    : [];

  saveCalcRequest.vinAssignments = values.vinConfigs.map(
    (x) =>
      new VinAssignment({
        position: x.position,
        vin: x.vin ?? undefined,
        vinDate: x.vinDate ?? undefined,
        vinIssue: x.vinIssue ?? undefined,
        vinStatus: x.vinStatus ?? undefined,
        commissionNumber: x.commissionNumber ?? undefined,
      })
  );

  saveCalcRequest.credibilityCheck = new CredibilityCheck({
    ksvRating: values.credibilityCheck.ksvRating ?? undefined,
    roracUrl: values.credibilityCheck.roracUrl ?? undefined,
  });

  return saveCalcRequest;
};

export const mapModelConfigToObjectConfig = (model: ModelFormValues): ObjectConfigValues => ({
  listPrice: model.listPrice,
  extrasPrice: model.specialEquipment,
  listPriceIncludingExtras: model.lpSaTotal,
  totalPrice: model.totalPrice,
  coFinancedExtras: 0,
  purchasePrice: model.totalPrice,
  calculationBase: model.totalPrice,

  discountPercent: model.discountInPercentages,
  discountInEuro: model.discountInEuro,
  registrationDate: model.registrationDate,
  initialMileage: model.mileage,
  commissionNumber: model.commissionNumber,
});

export const mapSalesAidValuesToView = (s: SalesAidValues): SalesAidView => {
  return new SalesAidView({
    ...s,
    id: s.id ?? undefined,
    description: s.description ?? undefined,
    notice: s.notice ?? undefined,
    specialOfferId: s.specialOfferId ?? undefined,
    descriptionFileId: s.descriptionFileId ?? undefined,
    isEditable: s.isEditable,
    subsidies: s.subsidies.map(
      (sub) =>
        new SubsidyView({
          ...sub,
          fixum: sub.fixum ?? undefined,
          percentage: sub.percentage ?? undefined,
          isVisible: sub.isVisible ?? undefined,
        })
    ),
    isLocked: s.isLocked ?? false,
  });
};

export const mapVinConfigToView = (vin: VinConfigValues): VinAssignment => {
  return new VinAssignment({
    vin: vin.vin ?? undefined,
    vinDate: vin.vinDate ?? undefined,
    vinIssue: vin.vinIssue ?? undefined,
    vinStatus: vin.vinStatus ?? undefined,
    vinState: vin.vinState ?? undefined,
    commissionNumber: vin.commissionNumber ?? undefined,
    position: vin.position,
  });
};

export const mapConvOptionValuesToView = (co: ConversionOptionValues): ConversionOption => {
  return new ConversionOption({
    id: Number.isInteger(co.id) ? co.id : 0,
    title: co.title,
    price: co.price,
    supplierId: co.supplierId || undefined,
    unknownSupplier: co.supplierId || !co.unknownSupplier ? undefined : co.unknownSupplier,
    fileId: co.fileId ?? undefined,
    fileName: co.fileName ?? undefined,
    isFileArchived: co.isFileArchived ?? undefined,
  });
};

export const createCommonServiceRequest = (
  service: ServiceValues,
  dealId: number | undefined,
  calculationId: number | undefined,
  financingTypeId: number
) => {
  return {
    cacheGuid: service.cacheGuid ?? undefined,
    dealId: dealId ?? -1,
    calculationId: calculationId,
    serviceVariantId: service.serviceVariantId ?? undefined,
    variantNumber: undefined,
    serviceProductId: service.apiconServiceProduct?.selectedServiceProductId ?? undefined,
    mileageId: service.mileageId ?? -1,
    duration: service.duration ?? -1,
    isServiceVariantsResetRequest: false,
    serviceChargeNet: service.serviceChargeNet,
    configurationID: service.apiconPriceForm?.configurationId ?? '',
    productOptionIds: service.apiconServiceProduct?.selectedProductOptions,
    productIndustryId: service.apiconServiceProduct?.selectedProductIndustryId ?? undefined,
    productUsageId: service.apiconServiceProduct?.selectedProductUsageId ?? undefined,
    technicalOptionIds: service.apiconServiceProduct?.selectedTechnicalOptions,
    campaignId: service.apiconServiceProduct?.selectedCampaignId ?? undefined,
    priceNet: service.apiconPriceForm?.priceNet ?? undefined,
    baumuster: service.baumuster ?? '',
    nstCode: service.nstCode ?? '',
    uptimeInfos: undefined,
    fundingProductId: financingTypeId,
    totalPrice: service.apiconPriceForm?.totalPrice ?? undefined,
    priceFormState:
      service.apiconPriceForm?.priceFormState ?? ApiconSpecialCasePriceFormState.Initial,
    specialCaseRequestBundle: new ApiconSpecialCaseRequestBundle({
      comment: service.apiconSpecialCase?.comment ?? '',
      phone: service.apiconSpecialCase?.phone ?? '',
      eMail: service.apiconSpecialCase?.email ?? '',
      offerID: service.apiconSpecialCase?.offerId ?? '',
      specialCaseID: service.apiconSpecialCase?.specialCaseId ?? '',
    }),
    preCheckServiceProductSelectionsFromRequest: true,
    preCheckServiceProductSelectionsFromApicon: false,
    isPriceFormSpecialCaseMandatory: service.apiconPriceForm?.specialCaseMandatory ?? false,
    isServiceVariantSaveRequest: false,
  };
};
