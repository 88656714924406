import { Box, BoxProps } from '@mui/material';
import { FinancesCalculationConfiguration } from './FinancesCalculationConfiguration';
import { FinancesCalculationParameter } from './FinancesCalculationParameter';
import { useTranslation } from 'react-i18next';
import { Control } from 'react-hook-form';
import { FinancesDrawerFormValues, SalesAidValues } from '../RightDrawerSchema';
import {
  Fee,
  OptionItem,
  SalesAidLimitViolationView,
  SalesAidView,
  SubmittedDealOverviewIPagedList,
  SubsidyRecipientView,
  SubsidyView,
} from '../../../../../generated/ApiClient';
import { FinancesCalculationPromotions } from './FinancesCalculationPromotions';
import { ExtendedConfiguration } from './extendedConfig/ExtendedConfiguration';
import { useEffect, useState } from 'react';
import RestHttpClient from '../../../../../common/RestHttpClient';
import i18n from '../../../../../common/i18n/i18n';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';

interface Props extends BoxProps {
  dealId?: number;
  calculationId?: number;
  calcNum?: string;
  control: Control<FinancesDrawerFormValues>;
  financingTypes: OptionItem[];
  mileages: OptionItem[];
  interestCalcMethods: OptionItem[];
  rateCategories: OptionItem[];
  fundSources: OptionItem[];
  businessRelationships: OptionItem[];
  residualValueContracts: OptionItem[];
  specialOffers: SalesAidView[];
  onRelevantChange: (name: string, value: unknown) => void;
  dealSalesAids: SalesAidValues[];
  onAddSalesAid: (salesAid: SalesAidValues) => void;
  onEditSalesAid: (salesAid: SalesAidValues, index: number) => void;
  subsidies: SubsidyView[];
  violations: SalesAidLimitViolationView[];
  fees: Fee[];
  hasDeposit: boolean;
  hasClosingBalance: boolean;
  hasResidualValue: boolean;
  isFinancingLeasing: boolean;
  isDepositReadonly: boolean;
  isDownPaymentReadonly: boolean;
  isAccountOwnerRequired: boolean;
  showSourceOfFunds: boolean;
  isDirectInkassoVisible: boolean;
  isResidualValueReadonly: boolean;
  isResidualValueConversionOptionsReadonly: boolean;
  isCredit: boolean;
  isLeasing: boolean;
  isIncludingVat: boolean;
  vatFree: boolean;
  loading: boolean;
  isDurationReadonly: boolean;
  isMileageReadonly: boolean;
  readonly?: boolean;
  fetchSubmittedDeals: (
    page?: number,
    id?: string,
    salesPersonName?: string,
    creationDate?: Date,
    customerName?: string,
    orderSelectors?: string[]
  ) => Promise<SubmittedDealOverviewIPagedList>;
  setValue: UseFormSetValue<FinancesDrawerFormValues>;
}

export const FinancesCalculation = ({
  dealId,
  calculationId,
  calcNum,
  control,
  financingTypes,
  mileages,
  interestCalcMethods,
  rateCategories,
  fundSources,
  businessRelationships,
  onRelevantChange,
  onAddSalesAid,
  onEditSalesAid,
  subsidies,
  violations,
  fees,
  residualValueContracts,
  specialOffers,
  dealSalesAids,
  hasResidualValue,
  isFinancingLeasing,
  hasClosingBalance,
  hasDeposit,
  isDepositReadonly,
  isDownPaymentReadonly,
  isAccountOwnerRequired,
  showSourceOfFunds,
  isDirectInkassoVisible,
  isResidualValueReadonly,
  isResidualValueConversionOptionsReadonly,
  isIncludingVat,
  loading,
  vatFree,
  isCredit,
  isLeasing,
  isMileageReadonly,
  isDurationReadonly,
  readonly,
  fetchSubmittedDeals,
  setValue,
  ...others
}: Props) => {
  const { t } = useTranslation();

  const [givers, setGivers] = useState<OptionItem[]>([]);
  const [recipients, setRecipients] = useState<SubsidyRecipientView[]>([]);
  const [recipientUnits, setRecipientUnits] = useState<{ [index: number]: string }>({});

  useEffect(() => {
    RestHttpClient.getSubsidyGiver().then((res) => {
      setGivers(
        res.data.map(
          (g) =>
            new OptionItem({
              label: i18n.t([
                `deals.new.financesConfiguration.calculationDrawer.giverValues.${g.value}`,
                g.label,
              ]),
              value: g.value,
            })
        )
      );
    });
    RestHttpClient.getSubsidyRecipient().then((res) => {
      const units: { [index: number]: string } = {};
      setRecipients(res.data);
      res.data.forEach((rec) => {
        units[rec.value] = i18n.t([
          `deals.new.financesConfiguration.calculationDrawer.subsidyUnits.${rec.unit}`,
          rec.unit,
        ]);
      });
      setRecipientUnits(units);
    });

    // eslint-disable-next-line
  }, []);

  return (
    <Box {...others}>
      <Box sx={{ m: 2 }}>
        {calcNum ? (
          <h2 style={{ marginBottom: 16 }}>
            {t('deals.new.financesConfiguration.calculationDrawer.editId', { id: calcNum })}
          </h2>
        ) : (
          <h2 style={{ marginBottom: 16 }}>
            {t('deals.new.financesConfiguration.calculationDrawer.edit')}
          </h2>
        )}
        <FinancesCalculationPromotions
          control={control}
          loading={loading}
          onRelevantChange={onRelevantChange}
          specialOffers={specialOffers}
          onAddSalesAid={onAddSalesAid}
          onEditSalesAid={onEditSalesAid}
          dealSalesAids={dealSalesAids}
          givers={givers}
          recipients={recipients}
          recipientUnits={recipientUnits}
          readonly={readonly}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-evenly',
        }}
      >
        <FinancesCalculationConfiguration
          dealId={dealId}
          calculationId={calculationId}
          onRelevantChange={onRelevantChange}
          control={control}
          financingTypes={financingTypes}
          rateCategories={rateCategories}
          interestCalcMethods={interestCalcMethods}
          mileages={mileages}
          residualValueContracts={residualValueContracts}
          hasResidualValue={hasResidualValue}
          loading={loading}
          isDurationReadonly={isDurationReadonly}
          isMileageReadonly={isMileageReadonly}
          readonly={readonly}
          fetchSubmittedDeals={fetchSubmittedDeals}
          setValue={setValue}
        />
        <FinancesCalculationParameter
          onRelevantChange={onRelevantChange}
          control={control}
          fundSources={fundSources}
          businessRelationships={businessRelationships}
          hasClosingBalance={hasClosingBalance}
          hasResidualValue={hasResidualValue}
          isFinancingLeasing={isFinancingLeasing}
          hasDeposit={hasDeposit}
          isDepositReadonly={isDepositReadonly}
          isDownPaymentReadonly={isDownPaymentReadonly}
          isAccountOwnerRequired={isAccountOwnerRequired}
          showSourceOfFunds={showSourceOfFunds}
          isResidualValueReadonly={isResidualValueReadonly}
          isResidualValueConversionOptionsReadonly={isResidualValueConversionOptionsReadonly}
          isIncludeVat={isIncludingVat}
          vatFree={vatFree}
          isCredit={isCredit}
          isLeasing={isLeasing}
          loading={loading}
          readonly={readonly}
          isDirectInkassoVisible={isDirectInkassoVisible}
        />
      </Box>
      <ExtendedConfiguration
        control={control}
        subsidies={subsidies}
        violations={violations}
        isIncludeVat={isIncludingVat}
        vatFree={vatFree}
        givers={givers}
        recipientUnits={recipientUnits}
        fees={fees}
      />
    </Box>
  );
};
